import React, { useEffect, useReducer, useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/BottomPanel.scss";
import { Col, Collapse, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faHeart,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, FreeMode, Thumbs } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getDecodedToken, getToken } from "../utils/authUtils";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import CatalogListModal from "./Components/CatalogListModal";
import ChangeRoom from "./Components/ChangeRoom";
import SearchBottomsheet from "./Components/SearchBottomsheet";
import { setToast } from "./Components/Toast";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Scanner } from "@yudiel/react-qr-scanner";
import { toast, ToastContainer } from "react-toastify";
import { handleImageError } from "./Components/Noimage";

interface Filter {
  scopes?: { name: string }[];
  filters?: any;
}
const favoriteReducer = (state: any, action: any) => {
  switch (action.type) {
    case "TOGGLE_FAVORITE":
      return !state;
    default:
      return state;
  }
};
interface BottomPanelProps {
  selectedImage: any;
  compareStatus: boolean;
  onImageChange: (imageSrc: object) => void;
  onCompareImage: (compareStatus: boolean) => void;
  rotationAngles: { floor_rotation_angle: number; wall_rotation_angle: number };
  bottomPanelLoding: boolean;
  resetRotationAngles: () => void;
  setSelectedImageDegree: any;
  setSelectedImageDetails: any;
  onRotateSurface: (angle: number) => void;
  isOpen: boolean;
  togglePanel: () => void;
  setExitcompareModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowScannerModal: React.Dispatch<React.SetStateAction<boolean>>;
  showScannerModal: boolean;
  toggleScannerModal: () => void;
}
function BottomPanel({
  selectedImage,
  onImageChange,
  rotationAngles,
  onCompareImage,
  compareStatus,
  bottomPanelLoding,
  resetRotationAngles,
  setSelectedImageDegree,
  setSelectedImageDetails,
  onRotateSurface,
  isOpen,
  togglePanel,
  setExitcompareModalOpen,
  setShowScannerModal,
  toggleScannerModal,
  showScannerModal,
}: BottomPanelProps): JSX.Element {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [clickedSlideIndex, setClickedSlideIndex] = useState(-1);
  const [userToken, setUserToken] = useState<string | null>(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [productLoading, setProductLoading] = useState(false);
  const [productData, setProductData] = useState<
    { id: number; image_url_small: string }[]
  >([]);
  const isLikedParam = queryParams.get("is_liked");
  const [favoriteActive, dispatch] = useReducer(
    favoriteReducer,
    isLikedParam === "true"
  );
  const isForWallParam = queryParams.get("is_for_wall");
  const [isForWall, setIsForWall] = useState(true);
  const [isForFloor, setIsForFloor] = useState(true);
  const [visitorId, setVisitorId] = useState<number | null>(null);
  const [tileSizes, setTileSizes] = useState<string[]>([]);
  const [tileColors, setTileColors] = useState<string[]>([]);
  const [organizationId, setOrganizationId] = useState<number | null>(null);
  const images = [];
  const [favoriteStatus, setFavoriteStatus] = useState(
    Array(images.length).fill(false)
  );
  const [searchParams] = useSearchParams();
  const [ishome, setIshome] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isRotate, setIsRotate] = useState(false);
  const [isCompare, setIsCompare] = useState(true);
  const room_id = searchParams.get("room_id");
  const isProductDataEmpty = !productData || productData.length === 0;
  const [showChangeRoomModal, setShowChangeRoomModal] = useState(false);
  const hideChangeRoomModal = () => {
    setShowChangeRoomModal(false);
  };
  const ShowChangeRoomModal = () => {
    setShowChangeRoomModal(true);
  };

  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
  const [colorState, setColorState] = useState<any>([]);
  const [categoryState, setCategoryState] = useState<any>([]);
  const [producttypeState, setProducttypeState] = useState<any>([]);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showRating, setShowRating] = useState(0);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [showCatalogModal, setShowCatalogModal] = useState(false);
  const [scannedResults, setScannedResults] = useState<string[]>([]);
  const [scanproductData, setScanproductData] = useState<any[]>([]);
  const [scanclickedSlideIndex, setScanclickedSlideIndex] = useState<number>(0);
  const [showSwiper, setShowSwiper] = useState<boolean>(false);
  const [scannedProductId, setScannedProductId] = useState<string | null>(null);
  const [ratings, setRatings] = useState(0);
  const fallBackSrc = "/images/no_image_1.svg";

  const handleScan = async (result: any) => {
    if (result && Array.isArray(result) && result.length > 0) {
      try {
        const rawData = result[0].rawValue;
        const parsedData = JSON.parse(rawData);

        const productId = parsedData.product_id;
        setScannedProductId(productId);
        await fetchProducts(productId);

        setScannedResults((prevResults) => [...prevResults, rawData]);
        setShowScannerModal(false);
        setShowSwiper(true);
      } catch (error) {
        console.error("Error parsing QR code data:", error);
        toast.error("Failed to scan QR code. Invalid data format.");
      }
    }
  };

  //=======================
  const fetchProducts = async (productId: string) => {
    try {
      if (userToken) {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/public_products/search`;

        const response = await axios.post(
          apiUrl,
          {
            product_id: productId,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        const filteredData = response.data.data.filter(
          (product: any) => product.id === productId
        );
        setScanproductData(filteredData);

        if (filteredData.length > 0) {
          toast.success("QR code scanned successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.warn("No product found for the scanned ID.");
        }
      }
    } catch (error) {
      console.error("Error fetching data from the API:", error);
      toast.error("Error fetching product data.");
    }
  };

  //=======================

  const handleSearch = () => {
    setShowBottomSheet(!showBottomSheet);
    applyWallFloorFilters();
  };
  useEffect(() => {
    handleVisitorProfile();
  }, []);

  const toggleRatingModal = () => {
    setIsRatingModalOpen((prevState) => !prevState);
  };
  const handleVisitorProfile = async () => {
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + `api/visitor_profile`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const visitorProfileData = response.data.data;
      setShowRating(visitorProfileData.rating[0].rating);
    } catch (error) {
      console.error("Error fetching Visitor Profile:", error);
    }
  };

  const handleStarClick = async (index: number) => {
    const newRating = index + 1;
    setRatings(newRating); 
    setShowRating(newRating);
    
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/visitor_ratings`;
      await axios.post(
        apiUrl,
        { rating: newRating },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      handleVisitorProfile();
      setToast("success", "Feedback received! Thanks for rating.");
    } catch (error) {
      console.error("Error fetching Visitor Rating:", error);
      setToast("error", "Something went wrong. Please try again later.");
    }
  };

  const useQueryParam = (paramName: any, paramValue: any) => {
    useEffect(() => {
      const currentQueryString = window.location.search;
      const queryParams = new URLSearchParams(currentQueryString);
      queryParams.delete(paramName);
      queryParams.append(paramName, String(paramValue));
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, "", newUrl);
    }, [paramName, paramValue]);
  };
  const useProductFetcher = (
    favoriteActive: any,
    isForWall: any,
    isForFloor: any
  ) => {
    useEffect(() => {
      getProducts();
    }, [favoriteActive, isForWall, isForFloor]);
  };
  const rotateSurface = () => {
    let newAngle = rotationAngle + 30;
    if (newAngle >= 361) {
      newAngle = 0;
    }
    setRotationAngle(newAngle);
    onRotateSurface(newAngle);
  };
  useQueryParam("is_liked", favoriteActive);
  useQueryParam("is_for_wall", isForWall);
  useQueryParam("is_for_floor", isForFloor);
  useProductFetcher(favoriteActive, isForWall, isForFloor);

  useEffect(() => {
    const token = getToken();
    if (token) {
      setUserToken(token);
      const decodedToken = getDecodedToken();
      const OrganizationId = decodedToken.data.organization_id;
      const visitorId = decodedToken.data.id;
      setVisitorId(visitorId);
      setOrganizationId(OrganizationId);
    }
  }, [isOpen]);

  const [slidesPerView, setSlidesPerView] = useState(14);

  useEffect(() => {
    function updateSlidesPerView() {
      const screenWidth = window.innerWidth;
      const newSlidesPerView = screenWidth < 768 ? 1 : 10;
      setSlidesPerView(newSlidesPerView);
    }
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isLikedParam = queryParams.get("is_liked");
    if (isLikedParam !== null) {
      dispatch({ type: "SET_FAVORITE", payload: isLikedParam === "true" });
    }
    if (userToken !== null) {
      getProducts();
    }
  }, [userToken, favoriteStatus, window.location.search, isLikedParam]);

  const handleFavoriteClick = async (index: any) => {
    try {
      setProductLoading(false);
      if (!userToken) {
        console.error("User is not authenticated.");
        return;
      }

      const productId = productData[index].id;

      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/product/like_unlike";
      await axios.post(
        apiUrl,
        {
          product_id: productId,
          visitor_id: visitorId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const updatedFavoriteStatus = [...favoriteStatus];
      updatedFavoriteStatus[index] = true;
      setFavoriteStatus(updatedFavoriteStatus);
    } catch (error) {
      console.error("Error adding product to favorites:", error);
    } finally {
      setProductLoading(false);
    }
  };

  const getProducts = async () => {
    try {
      if (userToken) {
        const currentQueryString = window.location.search;
        const queryParams = new URLSearchParams(currentQueryString);
        const tileColors = queryParams.getAll("color[]");
        const tileSizes = queryParams.getAll("size[]");
        setTileColors(tileColors);
        setTileSizes(tileSizes);

        let filter: Filter = {};
        filter.scopes = [{ name: "productLike" }];
        filter.filters = [
          { field: "is_for_wall", value: isForWall },
          { field: "is_for_floor", value: isForFloor },
        ];

        if (tileSizes.length > 0) {
          filter.filters.push({
            field: "tiles_size_id",
            operator: "in",
            value: tileSizes,
          });
        }

        if (tileColors.length > 0) {
          filter.filters.push({
            field: "product_color_id",
            operator: "in",
            value: tileColors,
          });
        }

        const apiUrl =
          (process.env.REACT_APP_API_URL as string) +
          "api/public_products/search?is_liked=";

        const response = await axios.post(apiUrl + favoriteActive, filter, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        setProductData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };
  const applyWallFloorFilters = async () => {
    try {
      setProductLoading(true);
      await getProducts();
      setTimeout(() => {
        setProductLoading(false);
      }, 1300);
    } catch (error) {
      console.error("Error applying wall/floor filters:", error);
      setTimeout(() => {
        setProductLoading(false);
      }, 1300);
    }
  };

  const handleImageClick = (imageSrc: any, slideIndex: number) => {
    if (
      imageSrc &&
      Array.isArray(productData) &&
      slideIndex >= 0 &&
      slideIndex < productData.length
    ) {
      onImageChange(imageSrc);
      setClickedSlideIndex(slideIndex);
      const selectedImage = productData[slideIndex];
      if (selectedImage && selectedImage.id) {
        const selectedImageDetails: {
          wall_tile_id?: number;
          floor_tile_id?: number;
          searchQuery?: string;
        } = {};

        if (isForWall) {
          selectedImageDetails.wall_tile_id = selectedImage.id;
        }

        if (isForFloor) {
          selectedImageDetails.floor_tile_id = selectedImage.id;
        }

        if (!isForWall && !isForFloor) {
          selectedImageDetails.wall_tile_id = selectedImage.id;
          selectedImageDetails.floor_tile_id = selectedImage.id;
        }
        selectedImageDetails.searchQuery = "";
        setClickCount((prevCount) => prevCount + 1);
        setSelectedImageDetails(selectedImageDetails);
        if (clickCount === 10) {
          if (showRating === 0) {
            setIsRatingModalOpen(true);
          } else {
            setIsRatingModalOpen(false);
          }
        }
      } else {
        console.error('Selected image does not have an "id" property.');
      }
    } else {
      console.error("Invalid imageSrc or slideIndex.");
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      getColor();
      getCategory();
      getProductType();
    }
  }, []);

  const getColor = async () => {
    const userToken = getToken();
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/public_product_colors";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const colors = response.data.data;
      setColorState(colors);
    } catch (error) {
      console.error("Error fetching product Category:", error);
    }
  };

  const getCategory = async () => {
    const userToken = getToken();
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) +
        "api/public_product_categories";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const category = response.data.data;
      setCategoryState(category);
    } catch (error) {
      console.error("Error fetching product color:", error);
    }
  };

  const getProductType = async () => {
    const userToken = getToken();
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + "api/public_product_types";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const producttype = response.data.data;
      setProducttypeState(producttype);
    } catch (error) {
      console.error("Error fetching product color:", error);
    }
  };

  useEffect(() => {
    if (productData.length > 0) {
      handleImageClick(productData[0], 1);
    }
  }, [productData]);

  const toggleFavorite = () => {
    dispatch({ type: "TOGGLE_FAVORITE" });
  };

  const updateRotationDegrees = () => {
    const selectedImageDegree = {} as any;

    if (isForWall && !isForFloor) {
      selectedImageDegree.wall_rotation_angle =
        rotationAngles.wall_rotation_angle;
    } else if (isForFloor && !isForWall) {
      selectedImageDegree.floor_rotation_angle =
        rotationAngles.floor_rotation_angle;
    } else {
      selectedImageDegree.wall_rotation_angle =
        rotationAngles.wall_rotation_angle;
      selectedImageDegree.floor_rotation_angle =
        rotationAngles.floor_rotation_angle;
    }

    setSelectedImageDegree(selectedImageDegree);
  };
  useEffect(() => {
    getProducts();
  }, [favoriteActive]);
  useEffect(() => {
    updateRotationDegrees();
  }, [rotationAngles, isForWall, isForFloor]);

  const handleDetailsClick = () => {
    setIshome(true);
    setIsFavorite(false);
    setIsRotate(false);
    setIsCompare(true);
  };

  const handleFavorite = () => {
    setIsFavorite(true);
    setIshome(false);
    setIsRotate(false);
    setIsCompare(true);
    setIsFavorite(!isFavorite);
    applyWallFloorFilters();
  };

  const handleRotateClick = () => {
    setIsRotate(true);
    setIshome(false);
    setIsFavorite(false);
    setIsCompare(true);
    setIsRotate(!isRotate);
  };

  const handleCompareClick = () => {
    setIsCompare(true);
    setIshome(false);
    setIsFavorite(false);
    setIsRotate(false);
    onCompareImage(!compareStatus);
  };

  //====================//
  const openProductDetailsModal = () => {
    setShowProductDetailsModal(true);
  };

  const closeProductDetailsModal = () => {
    setShowProductDetailsModal(false);
  };

  const getColorName = (id: any) => {
    if (colorState?.length > 0 && id) {
      const colorName = colorState.find((item: any) => item.id === id);
      return colorName?.title;
    }
  };
  const getCategoryName = (id: any) => {
    if (categoryState?.length > 0 && id) {
      const categoryName = categoryState.find((item: any) => item.id === id);
      return categoryName?.title;
    }
  };

  const getProducttypeName = (id: any) => {
    if (categoryState?.length > 0 && id) {
      const producttypeName = producttypeState.find(
        (item: any) => item.id === id
      );
      return producttypeName?.title;
    }
  };
  const toggleCatalogModal = () => {
    setShowCatalogModal(!showCatalogModal);
  };
  return (
    <div className="">
      <div className="bottom-panel" style={{ padding: "0 0 50px 0" }}>
        <div className="fixed-bottom">
          <Row className="border border-1 bg-light">
            <Collapse in={isOpen} className=" swiper-background p-1">
              <div id="bottomPanel">
                {isProductDataEmpty ? (
                  <h2 className="text-center norecord mb-0">
                    No Products To Display
                  </h2>
                ) : (
                  <div>
                    <div className="slider-container">
                      <Swiper
                        navigation={false}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                        initialSlide={clickedSlideIndex}
                        onClick={(swiper) =>
                          setClickedSlideIndex(swiper.activeIndex)
                        }
                      >
                        {productData.map((imageSrc: any, index: any) => {
                          return (
                            <SwiperSlide key={index}>
                              <div
                                className={
                                  index === clickedSlideIndex
                                    ? " selected-tile-container"
                                    : ""
                                }
                              >
                                {productLoading ? (
                                  <div className="loading-skeleton">
                                    <div className="progress-value"></div>
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      className="add-to-favorite"
                                      onClick={() => handleFavoriteClick(index)}
                                      style={{ zIndex: "1" }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faHeart}
                                        size="2x"
                                        color={
                                          imageSrc?.is_liked !== 0
                                            ? "#ff3d3d"
                                            : "White"
                                        }
                                      />
                                    </div>
                                    <img
                                      alt=""
                                      style={{
                                        borderRadius: "4px",
                                        width: "70px",
                                        height: "70px",
                                      }}
                                      src={imageSrc.image_url_small}
                                      onError={(e) =>
                                        handleImageError(e, fallBackSrc, "70px", "70px")
                                      }
                                      onClick={() =>
                                        handleImageClick(imageSrc, index)
                                      }
                                      className={
                                        "swiperimg product-image-margin" +
                                        (index === clickedSlideIndex
                                          ? " selected-tile"
                                          : " unselected-tile")
                                      }
                                    />
                                  </React.Fragment>
                                )}
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                )}
                <Modal
                  centered
                  show={isRatingModalOpen}
                  onHide={toggleRatingModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Share Review</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <h5 className="fw-bold">How was your experience ?</h5>
                      <span>
                        Your review will help us improve our product and <br />{" "}
                        make it user friendly for more users.
                      </span>
                    </div>
                    <div>
                      <div
                        className="itemAnalytics"
                        style={{
                          width: 250,
                          height: 80,
                          background: "#F7F7F7",
                          borderRadius: 4,
                          border: "0.20px #989898 solid",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        <h5 className="mt-1" style={{ fontSize: "18px" }}>
                          Rate Your Experience
                        </h5>
                        <div className="d-flex justify-content-evenly">
                          {[...Array(5)].map((_, index) => (
                            <span
                              key={index}
                              onClick={() => handleStarClick(index)}
                              style={{ fontSize: "22px" }}
                            >
                              {index < showRating ? (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  style={{ color: "#0250F8" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faStar}
                                  style={{ opacity: 0.5 }}
                                />
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </Collapse>

            <Col xs={12} md={12} lg={12} className="">
              <div className="my-1">
                <div
                  className="button-container d-flex justify-content-around"
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  <button
                    className="btn btn-sm btn-bg-uniform border-0"
                    onClick={togglePanel}
                    style={{ width: "11%", backgroundColor: "red" }}
                  >
                    <FontAwesomeIcon
                      className="fa-xl"
                      icon={isOpen ? faChevronDown : faChevronUp}
                      color="white"
                    />
                  </button>

                  <button
                    type="button"
                    className="btn btn-sm"
                    style={{
                      width: "30%",
                      height: 32,
                      background: "#00B196",
                      color: "#ffffff",
                      borderRadius: 3,
                      fontSize: 12,
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    onClick={() => ShowChangeRoomModal()}
                  >
                    Change Room
                  </button>

                  <button
                    type="button"
                    className={`btn btn-sm px-2 wall_tour`}
                    style={{
                      width: "15%",
                      height: 32,
                      color: "white",
                      fontSize: 12,
                      fontWeight: "500",
                      wordWrap: "break-word",
                      background: isForWall ? "#007bff" : "black",
                    }}
                    onClick={() => {
                      setIsForWall((prevIsForWall) => !prevIsForWall);
                      applyWallFloorFilters();
                      resetRotationAngles();
                    }}
                  >
                    Wall
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm floor_tour`}
                    style={{
                      width: "15%",
                      height: 32,
                      color: "white",
                      fontSize: 12,
                      fontWeight: "500",
                      wordWrap: "break-word",
                      background: isForFloor ? "#007bff" : "black",
                    }}
                    onClick={() => {
                      setIsForFloor((prevIsForFloor) => !prevIsForFloor);
                      applyWallFloorFilters();
                      resetRotationAngles();
                    }}
                  >
                    Floor
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm d-flex justify-content-center DownloadCatalog"
                    style={{
                      width: "25%",
                      height: 32,
                      background: "#292929",
                      color: "#ffffff",
                      borderRadius: 3,
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    onClick={toggleCatalogModal}
                  >
                    <h6
                      style={{
                        fontSize: "12px",
                        marginTop: "4px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Catalog
                    </h6>
                    &nbsp;
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="faSquarePlusicon"
                    />{" "}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" bg-light mb-2">
            <Col>
              <div className="d-flex justify-content-between mt-2">
                <button
                  type="button"
                  className={`btn btn-sm ms-2 border-0 product_details`}
                  style={{
                    color: "black",
                    fontSize: 10,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                  onClick={() => {
                    openProductDetailsModal();
                    handleDetailsClick();
                  }}
                >
                  {ishome ? (
                    <img
                      src="icon/details.svg"
                      style={{ width: 13, height: 13, marginBottom: "5px" }}
                      alt="Details"
                    />
                  ) : (
                    <img
                      src="icon/details.svg"
                      style={{ width: 13, height: 13, marginBottom: "5px" }}
                      alt="Details"
                    />
                  )}
                  <br />
                  Details
                </button>
                <BottomSheet
                  className="ProductDetailsBottom"
                  open={showProductDetailsModal}
                  onDismiss={closeProductDetailsModal}
                  snapPoints={({ minHeight, maxHeight }) => [
                    minHeight,
                    maxHeight - 120,
                  ]}
                >
                  <div className="p-4">
                    <div
                      className="text-center"
                      style={{
                        color: "black",
                        fontSize: 25,
                        fontWeight: "500",
                        wordWrap: "break-word",
                      }}
                    >
                      Product Details
                    </div>
                    <div className="">
                      <div className="mt-3">
                        <h5
                          style={{
                            color: "#2469ff",
                            fontSize: 20,
                            fontWeight: "600",
                            wordWrap: "break-word",
                            textAlign: "center",
                          }}
                        >
                          {selectedImage?.product_name}
                        </h5>
                      </div>
                      <div className="mt-4 product-image">
                        <div className="image-wrapper rounded p-2" style={{border:"1px solid #dbd7d7"}}>
                          <img
                            className="img-fluid rounded"
                            style={{ maxWidth: "100%", height: "100%" }}
                            src={selectedImage?.image_url_mid}
                            onError={(e) =>
                              handleImageError(e, fallBackSrc, "200px", "200px")
                            }
                            alt="No Image"
                          />
                          <div className="product-add-to-favorite" style={{}}>
                            <FontAwesomeIcon
                              icon={faHeart}
                              size="2x"
                              color={
                                selectedImage?.is_liked !== 0 ? "red" : "White"
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <Row className="mt-3">
                        <Col className="mt-2 w-98">
                          <div
                            className="p-1 product-card"
                            style={{ background: "#E8ECF3", width: "100%" }}
                          >
                            <div className="product-name">Product Color</div>
                            <h5 className=" product-value">
                              {getColorName(selectedImage?.product_color_id)}
                            </h5>
                          </div>
                        </Col>
                        <Col className="mt-2 w-98">
                          <div
                            className=" p-1 product-card"
                            style={{ background: "#FAF0E6", width: "100%" }}
                          >
                            <div className="product-name">Product Size</div>
                            <h5 className=" product-value">
                              {selectedImage?.height} X {selectedImage?.width}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col className="mt-2 w-98">
                          <div
                            className="p-1 product-card"
                            style={{ background: "#F0FFF0", width: "100%" }}
                          >
                            <div className="product-name">Product Category</div>
                            <h5 className="product-value">
                              {getCategoryName(
                                selectedImage?.product_category_id
                              )}
                            </h5>
                          </div>
                        </Col>
                        <Col className="mt-2 w-98">
                          <div
                            className=" p-1 product-card"
                            style={{ background: "#FFF1F1", width: "100%" }}
                          >
                            <div className="product-name">Product Type</div>
                            <h5 className="product-value">
                              {getProducttypeName(
                                selectedImage?.product_type_id
                              )}
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </BottomSheet>

                <button
                  type="button"
                  className={`btn btn-sm favorite_tour border-0`}
                  style={{
                    color: "black",
                    fontSize: 10,
                    fontFamily: "Inter",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                  onClick={() => {
                    toggleFavorite();
                    handleFavorite();
                  }}
                >
                  {favoriteActive ? (
                    <img
                      src="icon/favorite_red.svg"
                      alt=""
                      style={{ width: 14.43, height: 13, marginBottom: "5px" }}
                    />
                  ) : (
                    <img
                      src="icon/favorite.svg"
                      alt=""
                      style={{ width: 14.43, height: 13, marginBottom: "5px" }}
                    />
                  )}
                  <br />
                  Favorite
                </button>
                <div>
                  <button
                    type="button"
                    className="btn btn-sm search_tour border-0 me-2"
                    onClick={handleSearch}
                    style={{
                      color: "black",
                      fontSize: 10,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    <img
                      src="icon/search.svg"
                      alt=""
                      style={{ width: 13, height: 13, marginBottom: "5px" }}
                    />
                    <br />
                    Search
                  </button>
                </div>
                {!compareStatus ? (
                  <button
                    type="button"
                    className="btn btn-sm border-0 compare_tour"
                    onClick={() => {
                      setIsCompare(true);
                      handleCompareClick();
                    }}
                    style={{
                      color: "black",
                      fontSize: 10,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    <img
                      src="icon/compare.svg"
                      alt=""
                      style={{ width: 12.14, height: 13, marginBottom: "5px" }}
                    />
                    <br />
                    Compare
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-sm border-0 compare_tour"
                    onClick={() => {
                      setIsCompare(false);
                      setExitcompareModalOpen(true);
                    }}
                    style={{
                      color: "black",
                      fontSize: 10,
                      fontWeight: "400",
                      wordWrap: "break-word",
                    }}
                  >
                    <img
                      src="icon/compare_blue.svg"
                      alt=""
                      style={{ width: 12.14, height: 13, marginBottom: "5px" }}
                    />
                    <br />
                    Exit Compare
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm border-0 rotate_tour"
                  onClick={() => {
                    rotateSurface();
                    handleRotateClick();
                  }}
                  style={{
                    color: "black",
                    fontSize: 10,
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                >
                  <img
                    src="icon/rotate.svg"
                    alt=""
                    style={{ width: 13, height: 13, marginBottom: "5px" }}
                  />
                  <br />
                  Rotate
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        centered
        show={showScannerModal}
        onHide={() => setShowScannerModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>QR Scanner</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "500px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Scanner
              styles={{ container: { width: "100%", height: "100%" } }}
              onScan={handleScan}
            />
          </div>

          {/* Conditionally show Swiper only after QR code scan */}
          {showSwiper && scanproductData.length > 0 && (
            <div className="mt-5">
              <div className="slider-container">
                <Swiper
                  navigation={false}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  className="mySwiper"
                  initialSlide={clickedSlideIndex}
                  onClick={(swiper) => setClickedSlideIndex(swiper.activeIndex)}
                >
                  {scanproductData.map((imageSrc: any, index: any) => (
                    <SwiperSlide key={index}>
                      <div
                        className={
                          index === clickedSlideIndex
                            ? "selected-tile-container"
                            : ""
                        }
                      >
                        <img
                          alt=""
                          style={{
                            borderRadius: "4px",
                            width: "70px",
                            height: "70px",
                          }}
                          src={imageSrc.image_url_small}
                          className={
                            index === clickedSlideIndex
                              ? "selected-tile"
                              : "unselected-tile"
                          }
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              
            </div>
          )}
          <div className="mt-3">
                {scannedProductId && (
                  <p>
                    Scanned Product ID: <strong>{scannedProductId}</strong>
                  </p>
                )}
              </div>
        </Modal.Body>
        <ToastContainer />
      </Modal>
      <ChangeRoom
        showChangeRoomModal={showChangeRoomModal}
        toggleChangeRoomModal={hideChangeRoomModal}
      />
      <CatalogListModal
        showCatalogModal={showCatalogModal}
        closeCatalogModal={toggleCatalogModal}
      />
      <SearchBottomsheet
        showBottomsSheetrModal={showBottomSheet}
        toggleBottomsheetModal={handleSearch}
        onImageChange={onImageChange}
        setSelectedImageDetails={setSelectedImageDetails}
        resetRotationAngles={resetRotationAngles}
      />
    </div>
  );
}

export default BottomPanel;
