import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";

const Campaign: React.FC = () => {
  const navigate = useNavigate();
  const Organization_Id = getCookie("organizationId");
  const Token = getCookie("authToken");
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1); 
  const [hasMore, setHasMore] = useState(true); 

  useEffect(() => {
    fetchCampaigns(1);
  }, []);

  const fetchCampaigns = async (pageNumber: number) => {
    if (Organization_Id && Token) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/campaigns?organization_id=${Organization_Id}&page=${pageNumber}`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        const campaignsData = response.data.data || [];

        if (campaignsData.length > 0) {
          setCampaigns((prevCampaigns) => [...prevCampaigns, ...campaignsData]);
          setFilteredCampaigns((prevCampaigns) => [...prevCampaigns, ...campaignsData]);
        }
        if (campaignsData.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }
  };

  const handleSearch = (searchValue: string) => {
    setSearchTerm(searchValue);
    const searchResult = campaigns.filter((campaign) =>
      campaign.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredCampaigns(searchResult);
  };

  const loadMoreCampaigns = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchCampaigns(nextPage);
  };

  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center mb-4 mt-1">
        <Col>
          <h1>
            <strong>Campaign List</strong>
          </h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <InputGroup.Text>
              <i className="bi bi-search"></i>
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search campaign"
              aria-label="Search campaign"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
        {filteredCampaigns.map((campaign, index) => (
          <Col xs={4} key={index} >
            <Card
              className="campaignlist-card"
              onClick={() => navigate(`${campaign.id}/campaign-view`)}
            >
              <Card.Body className="py-3 text-center">
                <Card.Text className="m-0 fs-3">
                  <b>{campaign.title}</b>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {hasMore && (
        <div className="d-flex justify-content-center mt-4 mb-5">
          <button className="btn btn-sm uniform-submit-btn" onClick={loadMoreCampaigns}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Campaign;
