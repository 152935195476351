import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";

const VisitorDetails = () => {
  const navigate = useNavigate();
  const Organization_Id = getCookie("organizationId");
  const Token = getCookie("authToken");
  const [showModal, setShowModal] = useState(false);
  const [visitor, setVisitor] = useState<any>([]);
  const [editVisitor, setEditVisitor] = useState<any>({
    email: "",
    mobile_number: "",
    status: "",
  });

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => {
    setEditVisitor({
      email: visitor.email,
      mobile_number: visitor.mobile_number,
      status: visitor.status,
    });
    setShowModal(true);
  };
  const location = useLocation();
  const pathname = location.pathname;
  const campaignId = pathname.split("/")[2];
  const visitorId = pathname.split("/")[4];
  const [productsToShow, setProductsToShow] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchVisitor();
  }, []);

  const fetchVisitor = async () => {
    if (Organization_Id && Token && campaignId && visitorId) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/campaign/${campaignId}/visitors/${visitorId}?organization_id=${Organization_Id}&include=productLikes,productLikes.products`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        const visitorData = response.data.data || [];
        console.log(visitorData, "visitorData");

        setVisitor(visitorData);
      } catch (error) {
        console.error("Error fetching visitors:", error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const filteredProducts = visitor?.product_likes?.filter((like: any) => {
    const product = like.products;
    const searchLower = searchTerm.toLowerCase();

    return (
      product.title?.toLowerCase().includes(searchLower) ||
      product.width?.toString().includes(searchTerm) ||
      product.height?.toString().includes(searchTerm)
    );
  });

  const handleLoadMore = () => {
    setProductsToShow((prev) => prev + 4);
  };

  const handelvisitorEdit = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/visitors/${visitorId}?organization_id=${Organization_Id}`;
      const response = await axios.put(
        apiUrl,
        {
          email: editVisitor.email,
          mobile_number: editVisitor.mobile_number,
          status: editVisitor.status,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      fetchVisitor();
      handleModalClose();
    } catch (error) {
      console.error("Error handling visitor edit:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditVisitor((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
 
  
  
 
  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center d-flex flex-wrap">
        <Col xs={12} md={5}>
          <h2 className="fw-bold">Visitor Details</h2>
        </Col>
        <Col
          xs={12}
          md={7}
          className="d-flex justify-content-md-end align-items-center gap-2"
        >
          <button className="btn btn-sm uniform-submit-btn mb-md-0">
            Visual Kiosk
          </button>
          <button
            className="btn btn-sm uniform-edit-btn mb-md-0"
            onClick={() => handleModalShow()}
          >
            Edit
          </button>
          <button
            className="btn btn-sm uniform-back-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </Col>
      </Row>
      <div className="rounded p-3 mt-5 visitor-details-table">
        {visitor && (
          <div>
            <div className="row mt-3">
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Email:
                  </div>
                  <div className="col-6 fw-bolder">{visitor.email}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Mobile:
                  </div>
                  <div className="col-6 fw-bolder">{visitor.mobile_number}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Organization Id:
                  </div>
                  <div className="col-6 fw-bolder">
                    {visitor.organization_id}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Tracking Code:
                  </div>
                  <div className="col-6 fw-bolder">{visitor.tracking_code}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Vendor Code:
                  </div>
                  <div className="col-6 fw-bolder">{visitor.vendor_code}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    History Padding:
                  </div>
                  <div className="col-6 fw-bolder">
                    {visitor.use_history_padding}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Blocked:
                  </div>
                  <div className="col-6 fw-bolder">{visitor.is_blocked}</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Top Window:
                  </div>
                  <div className="col-6 fw-bolder">
                    {visitor.is_in_top_window}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Created At:
                  </div>
                  <div className="col-6 fw-bolder">
                    {moment(visitor.created_at).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row mb-4">
                  <div className="col-6 visitor-details-label fw-bolder">
                    Updated At:
                  </div>
                  <div className="col-6 fw-bolder">
                    {moment(visitor.updated_at).format("DD-MM-YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Row className="align-items-center mb-4 mt-5">
        <Col>
          <h2 className="fw-bold">Favorite Products</h2>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <Form.Control
              type="text"
              placeholder="Search Products"
              aria-label="Search Products"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} 
            />
            <button className="btn btn-icon uniform-btn">
              <i className="bi bi-search"></i>
            </button>
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
      {filteredProducts?.length > 0 ? (
          filteredProducts.slice(0, productsToShow).map((like: any) => (
            <Col key={like.id} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <div className="border rounded d-flex flex-column favorite-products-perent-div">
                <div className="favorite-products-div d-flex justify-content-center">
                  <img
                    src={like.products.image_url}
                    alt={`Product ${like.products.id}`}
                    className="favorite-products-img"
                  />
                </div>
                <h5 className="text-secondary fw-bold mt-3 text-center">{like.products.width} X {like.products.height}</h5>
              </div>
            </Col>
          ))
        ) : (
          <p>No product likes available.</p>
        )}
      </Row>
      {filteredProducts?.length > productsToShow && (
        <div className="d-flex justify-content-center mt-4 mb-5">
          <button
            className="btn btn-sm uniform-submit-btn"
            onClick={handleLoadMore}
          >
            Load More
          </button>
        </div>
      )}
      <Modal show={showModal} onHide={handleModalClose} size="lg" centered>
        <Modal.Header
          closeButton
          className="border-0 d-flex justify-content-between align-items-center"
        >
          <Modal.Title>
            <h2>
              <strong>Visitor Details Edit</strong>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="email" className="fw-semibold lebal-font">
                    Email*
                  </label>
                  <input
                    type="email"
                    value={editVisitor.email}
                    id="email"
                    name="email"
                    onChange={handleInputChange}
                    placeholder="Enter Your Email"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="mobile" className="fw-semibold lebal-font">
                    Mobile*
                  </label>
                  <input
                    type="text"
                    value={editVisitor.mobile_number}
                    id="mobile"
                    name="mobile_number"
                    onChange={handleInputChange}
                    placeholder="Enter Your Mobile Number"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="status" className="fw-semibold lebal-font">
                    Status*
                  </label>
                  <input
                    type="text"
                    value={editVisitor.status}
                    id="status"
                    name="status"
                    onChange={handleInputChange}
                    placeholder="Enter Status"
                    className="form-control form-control-lg form-control-solid mt-1"
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="btn btn-sm uniform-submit-btn"
            onClick={handelvisitorEdit}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisitorDetails;
