import React, { useState } from "react";
import { Form, Image, Spinner } from "react-bootstrap";
import { setCookie } from "typescript-cookie";

import { useNavigate } from "react-router-dom";
import { setToast } from "../App/Components/Toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resetStatus, setResetStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setCookie("resetEmail", email);

    const apiUrl = process.env.REACT_APP_API_URL + "api/reset_password_link";
    const payload = { email };
    try {
      setLoading(true);
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        setToast("success", data.message);
        navigate(-1);
      } else {
        console.error("An error occurred", data);
        setToast("error", data.message || "Something went wrong");
        setResetStatus("error");
      }
    } catch (error) {
      console.error("An error occurred", error);
      setToast("error", "Something went wrong");
      setResetStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-99pr">
      <div className="row">
        <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
          <div className="signin-form-wrapper">
            <div className="text-center">
              <img
                src="/images/Spaceai_kiosk_logo.svg"
                className="d-inline-block align-top signin-logo mb-5"
                alt="Space AI Logo"
              />
            </div>
            <h1 className="mt-5 text-center">Forgot Password</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email" className="pt-2">
                <Form.Control
                  className="form-control form-control-lg form-control-solid fs-5"
                  type="email"
                  placeholder="Enter Register email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              {resetStatus === "error" && (
                <p className="text-danger ">
                  Invalid email. Please check your email address and try again.
                </p>
              )}
              <div className="d-flex justify-content-end gap-2">
                <button
                  type="button"
                  className="btn uniform-back-btn mt-4 btn-sm"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn uniform-btn mt-4 btn-sm"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="loadingspinnerbtn">
                    <Spinner
                      as="span"
                      animation="border"
                      className="m-1"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                  ) : (
                    <span className="fs-5">Done</span>
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div className="col-md-6 col-12 d-none d-lg-flex justify-content-end bg-white">
          <Image
            src="/images/console_sign_in_image.jpg"
            className="signin-image"
            alt="Placeholder Image"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
