import React, { useEffect, useState, useRef, lazy } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./styles/sidebar.scss";
import "./styles/styles.css";
import "./styles/icons.css";
import { Modal, Container } from "react-bootstrap";
import BottomPaneL from "./App/BottomPanel";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import "./Loading.css";
import SignIN from "./App/Components/SignIn";
import { clarity } from "clarity-js";
import Tour from "reactour";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";

const Navbar = lazy(() => import("./App/Components/Navbar"));

function App() {
  const [showModal, setShowModal] = useState<boolean>(true);
  const [showSlider, setShowSlider] = useState<boolean>(
    Boolean(Cookies.get("hasCompletedSlider")) || false
  );
  const [selectedImage, setSelectedImage] = useState({});
  const [compareImage, setCompareImage] = useState<boolean>(false);
  const [bottomPanelLoding, setBottomPanelLoding] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rotationAngles, setRotationAngles] = useState({
    floor_rotation_angle: 0,
    wall_rotation_angle: 0,
  });
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isTourCookieSet, setIsTourCookieSet] = useState(
    Cookies.get("hasCompletedTour") || null
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSliderCookieSet, setIsSliderCookieSet] = useState(
    Cookies.get("hasCompletedSlider") || null
  );
  const reactourRef = useRef<Tour | null>(null);
  const [selectedImageDegree, setSelectedImageDegree] = useState<any>();
  const [selectedImageDetails, setSelectedImageDetails] = useState<any>();
  const [exitcompareModalOpen, setExitcompareModalOpen] = useState(false);
  const [exitcompareModalOpenBrowserView, setExitcompareModalOpenBrowserView] =
    useState(false);
  const sliderRef = useRef<Slider | null>(null);
  const [orientation, setOrientation] = useState(window.orientation);
  const [showModalhorizontal, setShowModalhorizontal] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [showScannerModal, setShowScannerModal] = useState(false);
  const toggleScannerModal = () => {
    setShowScannerModal(!showScannerModal);
  };
  const toggleBottomPanel = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobile = window.matchMedia("(max-width: 767px)").matches;
      const isLandscape = window.orientation !== 0;
      setShowModalhorizontal(isMobile && isLandscape);
    };
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const roomId = Cookies.get("room_id");
  const handleCloseModal = () => {
    setShowModal(false);
    if (isSliderCookieSet) {
      setShowSlider(true);
    }
    // window.location.reload();
  };
  const handleNextButtonClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const startTour = () => {
    setTimeout(() => {
      setIsTourOpen(true);
    }, 3500);
  };

  useEffect(() => {
    const user = Cookies.get("user");

    if (user) {
      setShowModal(false);
      if (!isTourCookieSet) {
        startTour();
      }
    } else {
      setShowModal(true);
    }
    closeLoading();
  }, [isTourCookieSet]);

  const steps = [
    {
      selector: ".bm-burger-button", // Another step
      content: () => (
        <text className="tour-text">Click here and see company details.</text>
      ),
    },

    {
      selector: ".wall_tour", // Replace with your target element selector
      content: () => (
        <text className="tour-text">
          Click here and view wall Tiles designs.
        </text>
      ),
    },
    {
      selector: ".floor_tour", // Another step
      content: () => (
        <text className="tour-text">
          Click here and view floor tiles designs.
        </text>
      ),
    },
    {
      selector: ".DownloadCatalog", // Another step
      content: () => (
        <text className="tour-text">Click here to Download Catalog.</text>
      ),
    },
    {
      selector: ".product_details", // Another step
      content: () => (
        <text className="tour-text">
          click here and see your product details.
        </text>
      ),
    },
    {
      selector: ".favorite_tour", // Another step
      content: () => (
        <text className="tour-text">
          Show your Favorites Wall & Floor Tiels.
        </text>
      ),
    },
    {
      selector: ".search_tour", // Another step
      content: () => (
        <text className="tour-text">
          click here and see your Search Filter.
        </text>
      ),
    },
    {
      selector: ".compare_tour", // Another step
      content: () => (
        <text className="tour-text">
          click here and see your Compare Images.
        </text>
      ),
    },
    {
      selector: ".rotate_tour", // Another step
      content: () => (
        <text className="tour-text">
          click here and see your Rotate Images.
        </text>
      ),
    },
  ];

  const handleImageChange = (imageSrc: any) => {
    setSelectedImage(imageSrc);
  };
  const [searchParams] = useSearchParams();
  const organization_id = searchParams.get("organization_id");
  const organization_handle = searchParams.get("o");

  useEffect(() => {
    if (process.env.CLARITY_ID && process.env.GA_TRACKING_ID) {
      clarity.setup({
        projectId: process.env.CLARITY_ID as string,
      });
      clarity.initialize(process.env.GA_TRACKING_ID as string);
    }
  }, []);

  useEffect(() => {
    const user = Cookies.get("user");

    user ? setShowModal(false) : setShowModal(true);
  }, [showModal]);

  const resetRotationAngles = () => {
    setRotationAngles({ floor_rotation_angle: 0, wall_rotation_angle: 0 });
  };

  const handleRotateSurface = (angle: number) => {
    setRotationAngles({
      floor_rotation_angle: angle,
      wall_rotation_angle: angle,
    });
  };

  useEffect(() => {
    const user = Cookies.get("user");
    const userData = user ? JSON.parse(user) : null;

    if (userData && userData.organization_handle !== organization_handle) {
      Cookies.remove("user");
    }

    setShowModal(!user);
  }, [organization_handle]);

  const handleCompareImage = (props: any) => {
    setCompareImage(props);
  };

  const closeLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };
  useEffect(() => {
    const user = Cookies.get("user");

    user ? setShowModal(false) : setShowModal(true);

    closeLoading();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleFinishButtonClick = () => {
    setShowSlider(true);
    setIsSliderCookieSet("hasCompletedSlider");
    Cookies.set("hasCompletedSlider", "true", { expires: 365 });
  };
  return (
    <div>
      <Container className="space-ai-container app-container">
        <ToastContainer />
        <div>
          {showModalhorizontal && (
            <Modal
              centered
              className={`modal-xl ${
                showModalhorizontal ? "modal-horizontal-mobile" : ""
              }`}
              show={showModalhorizontal}
            >
              <Modal.Body>
                <div className="text-center">
                  <h5 className="text-danger">
                    Please Rotate your phone vertically for an enhanced
                    experience.
                  </h5>
                  <img
                    src="images/mobile-rotate-animation.gif"
                    alt=""
                    height={"100px"}
                    width={"100px"}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
        {!showSlider && !showModal && (
          <div className="slider-container intro-silder">
            <Slider {...settings} ref={sliderRef}>
              <div className="slider-main">
                <div className="img-div1"></div>
                <div className="text-div">
                  <h5 className="">Capture Your Space</h5>
                  <p className="mt-4">
                    Take a photo of your room or <br /> choose one from your
                    gallery
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm next-button fw-bold border-0"
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="slider-main">
                <div className="img-div2"></div>
                <div className="text-div">
                  <h5 className="">Choose Your Tile</h5>
                  <p className="mt-3">
                    Browse through our selection of tiles and <br /> colors to
                    find the perfect fit for your space
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm next-button fw-bold border-0"
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div className="slider-main">
                <div className="img-div3"></div>
                <div className="text-div">
                  <h5 className=""> Visualize in Real Time</h5>
                  <p className="mt-3">
                    Use our AI-powered tool to see how your chosen tile or{" "}
                    <br /> color looks in your room instantly
                  </p>
                  <button
                    type="button"
                    className="btn btn-sm next-button fw-bold border-0"
                    onClick={handleFinishButtonClick}
                  >
                    Finish
                  </button>
                </div>
              </div>
            </Slider>
          </div>
        )}
        {roomId && (
          <Tour
            steps={steps}
            showNavigation={true}
            className="shadow-none text-white bg-transparent fw-bold"
            isOpen={isTourOpen}
            onRequestClose={() => {
              setIsTourOpen(false);
              Cookies.set("hasCompletedTour", "true", { expires: 365 });
            }}
            ref={reactourRef}
          >
            <div
              className="d-flex justify-content-between"
              style={{ position: "absolute", top: "70px", left: "120px" }}
            >
              <button
                className="btn btn-sm ms-2 skip-btn"
                onClick={() => {
                  setIsTourOpen(false);
                  Cookies.set("hasCompletedTour", "true", { expires: 365 });
                }}
              >
                Skip
              </button>
            </div>
          </Tour>
        )}

        {isLoading ? (
          <div className="loading-container">
            <div className="loading"></div>
            <div id="loading-text">loading...</div>
          </div>
        ) : (
          showSlider && (
            <div>
              <React.Suspense fallback={null}>
                <Navbar
                  selectedImage={selectedImage}
                  compareStatus={compareImage}
                  onCompareImage={handleCompareImage}
                  onRotateSurface={handleRotateSurface}
                  onChangeBottomPanelStatus={(e: any) => {
                    setBottomPanelLoding(e);
                  }}
                  isDropdownOpen={isDropdownOpen}
                  openDropdown={() => setIsDropdownOpen(true)}
                  selectedImageDegree={selectedImageDegree}
                  selectedImageDetails={selectedImageDetails}
                  rotationAngles={rotationAngles}
                  isOpen={isOpen}
                  resetRotationAngles={resetRotationAngles}
                  exitcompareModalOpen={exitcompareModalOpen}
                  setExitcompareModalOpen={setExitcompareModalOpen}
                  toggleScannerModal={toggleScannerModal}
                />
              </React.Suspense>
            </div>
          )
        )}
        {!isLoading && !showModal && showSlider && (
          <BottomPaneL
            selectedImage={selectedImage}
            onImageChange={handleImageChange}
            onCompareImage={handleCompareImage}
            onRotateSurface={handleRotateSurface}
            compareStatus={compareImage}
            rotationAngles={rotationAngles}
            resetRotationAngles={resetRotationAngles}
            bottomPanelLoding={bottomPanelLoding}
            setSelectedImageDegree={setSelectedImageDegree}
            setSelectedImageDetails={setSelectedImageDetails}
            setExitcompareModalOpen={setExitcompareModalOpen}
            isOpen={isOpen}
            togglePanel={toggleBottomPanel}
            setShowScannerModal={setShowScannerModal}
            showScannerModal={showScannerModal}
            toggleScannerModal={toggleScannerModal}
          />
        )}
        <Modal
          dialogClassName="sigin-dialog"
          className="signin-model"
          show={showModal}
        >
          <SignIN onCloseModal={handleCloseModal} />
        </Modal>
      </Container>
    </div>
  );
}

export default App;
