import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import { setToast } from "../../src/App/Components/Toast";

const Profile = () => {
  const Token = getCookie("authToken");
  const Organization_Id = getCookie("organizationId");
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({
    name: "",
    email: "",
    mobile_no: "",
  });

  const handleProfile = async () => {
    try {
      const apiUrl = (process.env.REACT_APP_API_URL as string) + "api/profile";
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setData(response.data.data);
      setEditData({
        name: response.data.data.name,
        email: response.data.data.email,
        mobile_no: response.data.data.mobile_no,
      });
    } catch (error) {
      console.error("Error fetching Profile:", error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditData({
      name: data.name,
      email: data.email,
      mobile_no: data.mobile_no,
    });
  };

  const handleSave = async () => {
    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + `api/edit_profile?organization_id=${Organization_Id}`;
      await axios.post(
        apiUrl,
        { ...editData },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setData(editData);
      setIsEditing(false);
      setToast("success", "Your Profile Updated Successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
      setToast("error", "Error updating profile");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    handleProfile();
  }, []);

  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Card className="border">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h2 className="fw-bold">Profile Details</h2>
          <div className="d-flex">
            {isEditing ? (
              <span>
                <button
                  className="btn btn-sm uniform-edit-btn mb-md-0 me-2"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="btn btn-sm uniform-back-btn mb-md-0 me-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </span>
            ) : (
              <button
                className="btn btn-sm uniform-edit-btn mb-md-0 me-2"
                onClick={handleEdit}
              >
                Edit
              </button>
            )}
            <button
              className="btn btn-sm uniform-back-btn"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="rounded p-2 visitor-details-table">
            {data && (
              <div>
                <div className="row mt-3">
                  <div className="col-12 col-md-6">
                    <div className="row mb-4">
                      <div className="col-6 visitor-details-label fw-bolder">
                        Name:
                      </div>
                      <div className="col-6 fw-bolder">
                        {isEditing ? (
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={editData.name}
                            onChange={handleChange}
                          />
                        ) : (
                          data.name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="row mb-4">
                      <div className="col-6 visitor-details-label fw-bolder">
                        Email:
                      </div>
                      <div className="col-6 fw-bolder">
                        {isEditing ? (
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={editData.email}
                            onChange={handleChange}
                          />
                        ) : (
                          data.email
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-6">
                    <div className="row mb-4">
                      <div className="col-6 visitor-details-label fw-bolder">
                        Mobile:
                      </div>
                      <div className="col-6 fw-bolder">
                        {isEditing ? (
                          <input
                            type="text"
                            className="form-control"
                            name="mobile_no"
                            value={editData.mobile_no}
                            onChange={handleChange}
                          />
                        ) : (
                          data.mobile_no
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Profile;
