import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "typescript-cookie";

interface CampaignData {
  qr_code_path?: any; 
}

const CampaignView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const Organization_Id = getCookie("organizationId");
  const Token = getCookie("authToken");
  const [searchTerm, setSearchTerm] = useState("");
  const [visitors, setVisitors] = useState<any[]>([]);
  const [campaignData, setCampaignData] = useState<CampaignData>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchVisitors();
    fetchQrcode();
  }, []);

  const fetchVisitors = async () => {
    if (Organization_Id && Token && id) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/campaign/${id}/visitors?organization_id=${Organization_Id}&is_productLikes=true`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        const visitorData = response.data.data || [];
        setVisitors(visitorData);
      } catch (error) {
        console.error("Error fetching visitors:", error);
      }
    }
  };

  const sortedVisitors = visitors.sort(
    (a: any, b: any) => b.isActive - a.isActive
  );

  const filteredVisitors = sortedVisitors.filter(
    (visitor) =>
      (visitor.mobile_number &&
        visitor.mobile_number.toString().includes(searchTerm)) ||
      (visitor.email && visitor.email.includes(searchTerm))
  );
    
  const fetchQrcode = async () => {
    if (Organization_Id && Token && id) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/campaigns/${id}?organization_id=${Organization_Id}`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        setCampaignData(response.data.data); 
        console.log(response.data.data, "response.data.data");
        
      } catch (error) {
        console.error("Error fetching qrcode:", error);
      }
    }
  };

  const staticimage = "/images/demo_qr_code.png";
  return (
    <div className="kiosk-container mt-4 overflow-hidden">
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h2 className="fw-bold mb-2 mb-md-0">Campaign 1</h2>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end align-items-start gap-2 flex-column flex-md-row"
        >
          <button className="btn btn-sm uniform-submit-btn">
            Enable Kiosk Mode
          </button>
          <button
            className="btn btn-sm uniform-back-btn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={8} className="mb-3 mb-md-0 d-flex align-items-stretch">
          <div className="rounded w-100">
            <img
              src="/images/campaign_view_banner.jpg"
              className="rounded img-fluid h-100"
              style={{ objectFit: "cover" }}
              alt=""
            />
          </div>
        </Col>
        <Col xs={12} md={4} className="d-flex align-items-stretch">
          <div className="border border-1 rounded text-center w-100 p-3 d-flex flex-column justify-content-center align-items-center qr-box">
            <img
              src={campaignData.qr_code_path ? campaignData.qr_code_path : staticimage}
              className="mt-5 img-fluid"
              style={{ maxHeight: "100%", width: "auto", maxWidth: "75%" }}
              alt="QR Code"
            />
            <div className="d-flex justify-content-center align-items-center mt-4">
              <img src="/icon/qr_icon.svg" alt="" />
              <p className="mb-0 mx-2">Scan this QR code to get started</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="align-items-center mt-5 mb-4">
        <Col>
          <h2 className="fw-bold">Visitor List</h2>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <InputGroup className="me-2 input-search-field">
            <Form.Control
              type="text"
              placeholder="Search Visitor"
              aria-label="Search Visitor"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="btn btn-icon uniform-btn">
              <i className="bi bi-search"></i>
            </button>
          </InputGroup>
          <button className="btn btn-icon uniform-btn">
            <i className="bi bi-funnel"></i>
          </button>
        </Col>
      </Row>
      <Row>
        {filteredVisitors.map((visitor, index) => (
          <Col xs={12} key={index} className="mb-1">
            <Card
              className="campaign-list-card"
              onClick={() => navigate(`${visitor.id}/visitor-details`)}
            >
              <Card.Body className="py-3">
                <Card.Text className="m-0 fs-4 d-flex justify-content-between align-items-center flex-wrap">
                  <div>
                    <b className="text-start">
                      {visitor.mobile_number
                        ? visitor.mobile_number
                        : visitor.email || "No Email"}
                    </b>
                  </div>
                  <div>
                    <span
                      className={`badge ${
                        visitor.is_blocked === 0 ? "badge-green" : "badge-red"
                      }`}
                    >
                      {visitor.is_blocked === 0 ? "Active" : "Inactive"}
                    </span>
                  </div>

                  <div>
                    <button className="btn btn-sm uniform-btn width-for-fvt-product-btn">
                      Favorite Products : {visitor.totalProductLikes}
                    </button>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* <div className="d-flex justify-content-center mt-4 mb-5">
        <button className="btn btn-sm uniform-submit-btn">Load More</button>
      </div> */}
    </div>
  );
};

export default CampaignView;
