import React, { useState } from "react";
import { Form, Image, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { setCookie } from "typescript-cookie";
import { setToast } from "../../src/App/Components/Toast";

const SigninKiosk = () => {
  const ORGANIZATION_ID = "organizationId";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ email?: string; password?: string }>(
    {}
  );
  const navigate = useNavigate();

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let validationErrors: { email?: string; password?: string } = {};

    if (!validateEmail(email)) {
      validationErrors.email = "Please enter a valid email address.";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);

    const apiUrl = (process.env.REACT_APP_API_URL as string) + "api/login";
    const payload = { email, password };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const { token, user } = data;
        const expirationTime = Date.now() + 3600 * 1000;
        const organizationId = user.user_organizations[0].id;
        setCookie("authToken", token, { expires: expirationTime });
        setCookie(ORGANIZATION_ID, organizationId, { expires: expirationTime });
        setCookie("user", JSON.stringify(user), { expires: expirationTime });
        setToast("success", "Login successfully!");
        navigate("/kiosksapp");
      } else {
        setToast("error", "Invalid email or password. Please try again.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-99pr">
      <div className="row">
        <div className="col-lg-6 col-12 d-flex flex-column justify-content-center align-items-center bg-white">
          <div className="signin-form-wrapper">
            <div className="text-center">
              <img
                src="/images/Spaceai_kiosk_logo.svg"
                className="d-inline-block align-top signin-logo mb-5"
                alt="Space AI Logo"
              />
            </div>
            <h1 className="mt-5 text-center">Sign In</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="email" className="pt-2">
                <Form.Label className="fs-5 mb-0">Email</Form.Label>
                <Form.Control
                  className="form-control form-control-lg form-control-solid fs-5"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                  }}
                  required
                />
                {errors.email && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {errors.email}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label className="fs-5 mb-0 mt-1">Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    className="form-control form-control-lg form-control-solid"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: "",
                      }));
                    }}
                    required
                  />
                  <span
                    className="password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye-fill"></i>
                    ) : (
                      <i className="bi bi-eye-slash-fill"></i>
                    )}
                  </span>
                </div>
                {errors.password && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {errors.password}
                  </div>
                )}
              </Form.Group>

              <div className="float-end mt-1 mb-3">
                <Link
                  to="/kiosksapp/forgotpassword"
                  className="mt-2 text-center forgotpass"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="mt-3 mb-2 mb-md-0 btn uniform-btn btn-sm py-2 border-0 w-100"
                disabled={loading}
              >
                {loading ? (
                  <span className="loadingspinnerbtn">
                    <Spinner
                      as="span"
                      animation="border"
                      className="m-1"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  <span className="fs-5">Sign In</span>
                )}
              </button>
            </Form>
          </div>
        </div>
        <div className="col-md-6 col-12 d-none d-lg-flex justify-content-end bg-white">
          <Image
            src="/images/console_sign_in_image.jpg"
            className="signin-image"
            alt="Placeholder Image"
          />
        </div>
      </div>
    </div>
  );
};

export default SigninKiosk;
