import {
  faCircleUser,
  faSignOut,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import React from "react";
import { Dropdown, Image, Nav, NavItem, NavLink } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const KioskNavbar = () => {
  const navigate = useNavigate();
  const handleSignOut = () => {
    Cookies.remove("authToken");
    Cookies.remove("organizationId");
    Cookies.remove("user");
    window.location.reload();
  };
  const handleProfileClick = () => {
    navigate("/kiosksapp/Profile");
  };


  return (
    <div className="d-flex justify-content-between align-items-center py-3 bg-white border-bottom">
      <div></div>
      <div className="text-center">
        <img src="/images/Spaceai_kiosk_logo.svg" alt="Space AI Logo" />
      </div>
      <Nav>
        <Dropdown as={NavItem} align={{ lg: "end" }} className="p-2">
          <div className="Kiosk-Profile" title="Profile">
            <Dropdown.Toggle as={NavLink} className="p-0 text-white fs-3">
              <span className="">
                <FontAwesomeIcon icon={faCircleUser} size="xl" color="#387efe" />
              </span>
            </Dropdown.Toggle>
          </div>
          <Dropdown.Menu className="p-3 profile-dropdown">
          <Dropdown.Item className="mt-2 ps-1 fs-5" onClick={handleProfileClick}>
              <FontAwesomeIcon icon={faUser} className="me-2" />
              Profile
            </Dropdown.Item>
            <Dropdown.Divider className="mt-2" />
            <Dropdown.Item className=" ps-1 text-danger fs-5" onClick={handleSignOut}>
              <FontAwesomeIcon
                icon={faSignOut}
                className=""
                style={{ color: "#E82646" }}
              />{" "}
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav>
    </div>
  );
};

export default KioskNavbar;
