import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Container, Form, Spinner } from "react-bootstrap";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../utils/authUtils";

interface SignINProps {
  onCloseModal: () => void;
}

function SignIN(props: SignINProps) {
  const [email, setEmail] = useState<string>("");
  const [mobile_number, setMobile_Number] = useState<string>("");
  const [emailError, setEmailError] = useState<string>(""); 
  const [mobileError, setMobileError] = useState<string>(""); 
  const [generalError, setGeneralError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const organization_id = searchParams.get("organization_id");
  const product_type_id = searchParams.get("product_type_id");
  const organization_handle = searchParams.get("o");
  const track_id = searchParams.get("track_id");
  const owner_id = searchParams.get("owner_id");
  const iframe_id = searchParams.get("iframe_id");
  const [isIntroCookieSet] = useState(Cookies.get("hasCompletedIntro"));
  const [organization, setOrganization] = useState<any>(null);
  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [loadingOrganization, setLoadingOrganization] = useState<boolean>(true);

  useEffect(() => {
    if (!track_id || !owner_id || !iframe_id) {
      setErrorDisplayed(true);
      setGeneralError("Invalid request");
      return;
    }

    if (organization_handle) {
      getLogo();
    } else {
      setErrorDisplayed(true);
      setGeneralError("Invalid request");
    }
  }, []);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setGeneralError("");
    setEmailError("");
    setMobileError("");
    setIsLoading(true);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!email && !mobile_number) {
      setGeneralError("Please enter an email or mobile number.");
      setIsLoading(false);
      return;
    }

    if (email && !emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    if (mobile_number && mobile_number.length < 6) {
      setMobileError("Mobile Number should be at least 6 digits.");
      setIsLoading(false);
      return;
    } else if (mobile_number && !/^\d{6,}$/.test(mobile_number)) {
      setMobileError("Please enter a valid mobile number.");
      setIsLoading(false);
      return;
    }

    let data: any = {
      organization_handle,
    };
    if (email) {
      data.email = email;
    }

    if (mobile_number) {
      data.mobile_number = mobile_number;
    }

    try {
      const apiUrl =
        (process.env.REACT_APP_API_URL as string) + `api/visitor_signup`;
      const response = await axios.post(apiUrl, data, {
        params: {
          track_id: track_id,
          owner_id: owner_id,
          iframe_id: iframe_id,
        },
      });
      const userData: any = {
        email,
        mobile_number,
        token: response.data.token,
        organization_id,
        product_type_id,
        organization_handle,
      };

      if (email) {
        userData.email = email;
      }

      if (mobile_number) {
        userData.mobile_number = mobile_number;
      }

      userData.token = response.data.token;

      const userDataString = JSON.stringify(userData);
      Cookies.set("user", userDataString, { expires: 1 });
      props.onCloseModal();
    } catch (error) {
      setGeneralError("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  const getLogo = async () => {
    const userToken = getToken();
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/public/organizations/${organization_handle}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      const organizationData = response.data.data;
      setOrganization(organizationData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching organization logo:", error);
      setErrorDisplayed(true);
      setGeneralError("Invalid request");
    } finally {
      setLoadingOrganization(false);
    }
  };

  return (
    <Container fluid>
      {errorDisplayed ? (
        <div className="text-center" style={{ height: "330px" }}>
          <div className=" pt-5">
            <img
              src="icon/wrong icon.png"
              alt=""
              height={"130px"}
              width={"130px"}
            />
            <h4 className="mt-4">Invalid request</h4>
          </div>
        </div>
      ) : (
        <div className="custom-modal-container">
          <Form onSubmit={handleFormSubmit}>
            <div className="text-center">
              {loadingOrganization ? (
                <div className="skeleton-loading">
                  <div className="skeleton-loading-item skeleton-loading-header product-img rounded img-fluid"></div>
                </div>
              ) : (
                <img
                  className="object-fit-contain"
                  src={organization?.logo}
                  style={{
                    width: "120px",
                    height: "100px",
                    border: "none",
                  }}
                  alt=""
                />
              )}
            </div>
            <div className="border-0 text-center mt-2">
              <span className="fw-bold">
                To Personalize Your Experience, <br />
                Please Enter Your email or phone number{" "}
              </span>
            </div>
            <div className="mt-2">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  className="mb-2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && (
                  <div style={{ fontSize: "12px" , color: "red" }}>
                    {emailError}
                  </div>
                )}
              </Form.Group>
              <p className="or mt-2">OR</p>
              <Form.Group
                controlId="formBasicMobile"
                style={{ marginTop: "-10px" }}
              >
                <Form.Label>Mobile </Form.Label>
                <Form.Control
                  type="tel"
                  value={mobile_number}
                  onChange={(e) => {
                    setMobile_Number(e.target.value);
                    setMobileError("");
                  }}
                />
                {mobileError && (
                  <div style={{ fontSize: "12px" , color: "red" }}>
                    {mobileError}
                  </div>
                )}
              </Form.Group>
              {generalError && (
                <div className="mt-2" style={{ fontSize: "12px" , color: "red" }}>
                  {generalError}
                </div>
              )}
            </div>
            <div className="border-0 mt-3">
              <button
                className="w-100 btn uniform-btn"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Submitting...
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        </div>
      )}
      <div className=" text-center product-logo">
        <h6 className="">
          <span className="text-secondary" style={{ fontSize: "13px" }}>
            Powered by&nbsp;
          </span>{" "}
          <img src="logo.png" style={{ width: "90px" }} alt="" />
        </h6>
      </div>
    </Container>
  );
}

export default SignIN;
