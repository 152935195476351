import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import SigninKiosk from "./SigninKiosk";
import KioskNavbar from "./KioskNavBar";
import Campaign from "./Campaign";
import CampaignView from "./CampaignView";
import VisitorDetails from "./VisitorDetails";
import NotFound from "../App/Components/NotFound";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./ForgotPassword";
import KioskProfile from "./KioskProfile";

interface AppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => (
  <div>
    <KioskNavbar />
    {children}
  </div>
);

const KiosksRoute: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userToken = document.cookie
      .split(";")
      .find((row) => row.startsWith("authToken="));
    if (!userToken) {
      if (
        location.pathname !== "/kiosksapp/signin" &&
        location.pathname !== "/kiosksapp/forgotpassword"
      ) {
        navigate("/kiosksapp/signin");
      }
    }
  }, [navigate, location]);

  return (
    <div className="kiosk-body">
      <ToastContainer />
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout>
              <Campaign />
            </AppLayout>
          }
        />
        <Route path="/signin" element={<SigninKiosk />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/Profile"
          element={
            <AppLayout>
              <KioskProfile />
            </AppLayout>
          }
        />
        <Route
          path=":id/campaign-view"
          element={
            <AppLayout>
              <CampaignView />
            </AppLayout>
          }
        />
        <Route
          path=":id/campaign-view/:id/visitor-details"
          element={
            <AppLayout>
              <VisitorDetails />
            </AppLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default KiosksRoute;
